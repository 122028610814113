/**
 * 查询子系统列表
 */
export const getSubSystemsApi = () => useGet<SubSystem[]>('/deploy/sub-sys/list')

// 文件夹hover #f7f9fc
// checked #f2faff

/**
 * 查询全部子系统
 */
export const querySubSysListApi = () => useGet('/deploy/sub-sys/list')

/**
 * 修改某个子系统
 */
export const updateSubSysApi = async (data: any) => usePut('/deploy/sub-sys', data)

/**
 * 新增某个子系统
 */
export const addSubSysApi = async (data: any) => usePost('/deploy/sub-sys', data)

/**
 * 删除某个子系统
 */
export const delSubSysByIdApi = async (id: number) => useDelete(`/deploy/sub-sys/${id}`)
